import React from "react"
import { OgTags } from "../components/OpenGraph";
import Header from "../components/Header"
import TitleBanner from "../components/TitleBanner"
import ServiceCard from "../components/ServiceCard"
import Footer from "../components/Footer"
import Brands from "../components/Brands"
import Testimonials from "../components/Testimonials"
import ContentImage from "../components/ContentImage"

const logo = [
  {
    type: 'partner',
    name: 'Shopify Partners',
    image: require('../assets/svg/shopify-partners.svg')
  }, {
    type: 'partner',
    name: 'BigCommerce Partner',
    image: require('../assets/svg/bigcommerce-partner.svg')
  }
]

const services = [
  {
    type: 'maintenance',
    image: require('../assets/svg/Wordpress-icon.svg'),
    title: 'WordPress',
    prefix: 'Premium Support',
    subtitle: '7-day satisfaction guarantee',
    linkText: 'Learn more',
    linkPath: '/wordpress-help'
  }, {
    type: 'maintenance',
    image: require('../assets/svg/woocommerce-icon.svg'),
    title: 'WooCommerce',
    prefix: 'Premium Support',
    subtitle: '7-day satisfaction guarantee',
    linkText: 'Learn more',
    linkPath: '/woocommerce-help'
  }, {
    type: 'maintenance',
    image: require('../assets/svg/shopify-icon.svg'),
    title: 'Shopify',
    prefix: 'Premium Support',
    subtitle: '7-day satisfaction guarantee',
    linkText: 'Learn more',
    linkPath: '/shopify-help'
  }, {
    type: 'maintenance',
    image: require('../assets/svg/bigcommerce-icon.svg'),
    title: 'BigCommerce',
    prefix: 'Premium Support',
    subtitle: '7-day satisfaction guarantee',
    linkText: 'Learn more',
    linkPath: '/bigcommerce-help'
  }
]

const testimonyList = [
  {
    text: "I would like to sincerely thank you for the excellent work you have done in developing and maintaining the G-24 website.",
    image: require('../assets/images/shichao-zhou.png'),
    name: 'Shichao Zhou',
    position: 'Research Officer, G-24 Secretariat'
  },
  {
    text: `Wanted to say I love my 2 sites. They are great. The site gets a ton of conversions. I'd say at least half my phone calls are form the website`,
    image: require('../assets/images/troy-parsons.png'),
    name: 'Troy Parsons',
    position: 'Owner, AAR Auro Glass'
  }, {
    text: `First of all, I just want to say big thank you for the quality and efficiency of your team's work. Pamela has been really amazing prompt with all project and tasks!`,
    image: require('../assets/images/renata-reis.png'),
    name: 'Renata Reis',
    position: 'Marketing Manager, collective.care'
  }
]

const cta = {
  hasBackground: true,
  hasButton: true,
  styleB: true,
  title: 'Need some help?',
  desc: `We know trying a new service can be scary, that's why we want to make this 100% risk-free for you and your company.`,
  image: require('../assets/images/mascot-cta.png'),
  buttons: [
    {
      type: 'normal',
      text: 'Book a call',
      link: 'https://calendly.com/fullstackhq/meeting',
      beacon: false,
      external: true
    }, {
      type: 'outline',
      text: 'Chat with us',
      link: '/',
      beacon: true
    }
  ]
}

function Maintenance() {
  return (
    <>
      <OgTags
        ogTitle="Outsource Web Development Support | Fullstack HQ"
        ogDescription="Want to hire an outsource developer? We've helped some of the world-class entrepreneurs & tech companies increase efficiency & reduce development costs."
        ogUrl="https://fullstackhq.com/support"
      />
      <Header />
      <main className="maintenance initialized-header">
        <TitleBanner
          title="Your personal tech team"
          subtitle="Grow your business with battle-tested development team at affordable rates."
        />
        <ServiceCard data={services} />
        <Brands type='partners' brands={logo} />
        <Testimonials
          title="Loved by world-class entrepreneurs &amp; tech companies"
          testimony={testimonyList}
        />
        <ContentImage content={cta} />
      </main>
      <Footer />
    </>
  )
}

export default Maintenance
